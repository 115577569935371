/** ref.
 * https://github.com/module-federation/core/issues/2672#issuecomment-2192671980
 * https://github.com/module-federation/module-federation-examples/blob/master/runtime-plugins/offline-remote/app1/offline-remote.js
 */
export default function () {
  const getErrorMessage = (id, error) => {
    if (error) {
      return `${id} is offline due to error: ${error}`;
    }

    return `${id} is offline`;
  };

  return {
    name: 'offline-remote-plugin',
    async errorLoadRemote({ id, error, from, origin }) {
      console.error(id, 'offline');
      const MyError = (await import('pages/_error')).default;

      if (from === 'build') {
        return () => ({
          __esModule: true,
          default: MyError,
          getServerSideProps: () => {
            return {
              props: {
                status_message: getErrorMessage(id),
              },
            };
          },
        });
      }

      const Sentry = require('@sentry/nextjs');

      return {
        default: MyError,
        getServerSideProps: async () => {
          Sentry.captureException(error);
          // Flushing before returning is necessary if deploying to Vercel, see
          // https://vercel.com/docs/platform/limits#streaming-responses
          await Sentry.flush(2000);

          return {
            props: {
              status_message: getErrorMessage(id, error),
            },
          };
        },
      };
    },
  };
}
